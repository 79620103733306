import classNames from "classnames"
import React, { ComponentProps, useContext, useRef } from "react"

import { Color } from "../../../../constants/V2/color"
import PillButton from "../../../elements/V2/Buttons/PillButton"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import Callout from "../../../elements/V2/Callout"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Grid from "../../../elements/V2/Grid"
import Section from "../../../elements/V2/Section"
import StickyContainer from "../../../elements/V2/StickyContainer"
import Typography from "../../../elements/V2/Typography"
import Video from "../../../elements/V2/Video"
import { NavigationV2Context } from "../../Navigation/context"

import { useElementObserver } from "@hooks/V2/useElementObserver"
import { getBackgroundColorClass, getAccentColor } from "@utils/V2/color"
import { useIsBreakpoint } from "@utils/V2/screen"

type Props = {
  headingText: string
  bodyText: string
  superscriptText?: string
  backgroundColor: Color
  primaryButtonText?: string
  primaryButtonLink?: string
  primaryButtonMobileLink?: string
  primaryButtonBorderColor: Color
  secondaryButtonText?: string
  secondaryButtonLink?: string
  videoUrl?: string
  imageUrl: string
  imageAlt: string
  mobileImageUrl?: string
  mobileImageAlt?: string
  callout?: Storyblok.Callout
  hasScrollArrow?: boolean
} & Partial<Storyblok.CTATrackingEvent>

const FloodHero = ({
  headingText,
  bodyText,
  superscriptText,
  backgroundColor,
  primaryButtonText,
  primaryButtonLink,
  primaryButtonMobileLink,
  primaryButtonBorderColor,
  secondaryButtonText,
  secondaryButtonLink,
  videoUrl,
  imageUrl,
  imageAlt,
  mobileImageUrl,
  mobileImageAlt,
  callout,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  hasScrollArrow = false,
  ...props
}: Props) => {
  const navigationContext = useContext(NavigationV2Context)
  const textContainerRef = useRef(null)
  const { isVisible: isCtaVisible, startObserving } =
    useElementObserver(textContainerRef)
  const accentColor = getAccentColor(backgroundColor)
  const isMobile = useIsBreakpoint("mobile")

  if (isMobile) {
    startObserving()
  }

  const imageProps: Omit<
    ComponentProps<typeof GatsbyStoryblokImage>,
    "image" | "alt"
  > = {
    loadType: "eager",
    quality: 80,
    aspectRatio: "fullWidth",
    className: "sm:min-h-screen sm:w-auto",
  }

  return (
    <div
      className={classNames(
        "-mb-lg relative",
        getBackgroundColorClass(backgroundColor)
      )}
      {...props}
    >
      {videoUrl ? (
        <Video
          video={videoUrl}
          coverImage={imageUrl}
          ImageAlt={imageAlt || "Hero image"}
          loadType="eager"
          aspectRatio="fullWidth"
          playbackMode="autoplay"
          className="sm:min-h-screen sm:w-full"
          playOnMobile={false}
        />
      ) : (
        <>
          <GatsbyStoryblokImage
            {...imageProps}
            image={imageUrl}
            alt={imageAlt || "Hero image"}
            className={classNames(imageProps.className, "hidden sm:block")}
          />
          <div className="relative sm:hidden">
            <GatsbyStoryblokImage
              {...imageProps}
              image={mobileImageUrl || imageUrl}
              alt={mobileImageAlt || imageAlt || "Hero image"}
              className={imageProps.className}
            />
            <div className="absolute bottom-0 h-1/2 w-full bg-gradient-to-b from-transparent to-black" />
          </div>
        </>
      )}

      <Section className="relative bottom-20 left-0 py-32 sm:absolute sm:py-gutter-sm md:py-gutter-md lg:py-gutter-lg xl:py-gutter-xl">
        <Grid>
          <div
            ref={textContainerRef}
            className="col-span-full flex flex-col gap-8 sm:col-span-2 md:col-start-1 md:col-end-4 md:mb-8 lg:col-start-1 lg:col-end-6 lg:mb-16"
          >
            {superscriptText && (
              <Typography
                text={superscriptText}
                font="grotesk"
                weight="book"
                size="subscript-lg"
                color={accentColor}
                className="uppercase"
              />
            )}

            {callout && (
              <Callout
                color={getAccentColor(backgroundColor)}
                classname="my-16"
                text={callout.text}
                highlightedWord={callout.highlightedWord}
              />
            )}

            <Typography
              text={headingText}
              font="grotesk"
              weight="medium"
              size="h2"
              color={accentColor}
              element="h1"
            />
            <Typography
              text={bodyText}
              font="grotesk"
              weight="book"
              size="body-md-fixed"
              color={accentColor}
              className="sm:hidden"
            />
            <Typography
              text={bodyText}
              font="grotesk"
              weight="book"
              size="body-lg-fixed"
              color={accentColor}
              className="hidden sm:block"
            />
          </div>
          <div className="col-span-full flex flex-col items-center gap-20 sm:flex-row md:gap-24">
            {primaryButtonText && primaryButtonLink && (
              <PillButton
                size="large"
                style="solid"
                text={primaryButtonText}
                color={primaryButtonBorderColor}
                linkUrl={primaryButtonLink}
                mobileLinkUrl={primaryButtonMobileLink}
                className="w-full sm:w-max"
                trackingEvent={trackingEvent}
                trackingEventKey={trackingEventKey}
                trackingEventValue={trackingEventValue}
                hasScrollArrow={hasScrollArrow}
              />
            )}

            {secondaryButtonLink && secondaryButtonText && (
              <TextButton
                style="text"
                text={secondaryButtonText}
                linkUrl={secondaryButtonLink}
                color={accentColor}
              />
            )}
          </div>
          {primaryButtonText && primaryButtonLink && (
            <StickyContainer
              isVisible={
                typeof isCtaVisible === "boolean" &&
                !isCtaVisible &&
                !navigationContext.isMobileMenuOpen
              }
            >
              <PillButton
                size="large"
                style="solid"
                text={primaryButtonText}
                overrideTextColor={accentColor}
                color={primaryButtonBorderColor}
                linkUrl={primaryButtonLink}
                mobileLinkUrl={primaryButtonMobileLink}
                trackingEvent={trackingEvent}
                trackingEventKey={trackingEventKey}
                trackingEventValue={trackingEventValue}
              />
            </StickyContainer>
          )}
        </Grid>
      </Section>
    </div>
  )
}

export default FloodHero
